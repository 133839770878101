import { Inject, Injectable } from "@angular/core"
import { Observable, of, concat, forkJoin } from "rxjs"
import { tap, map } from 'rxjs/operators'

import { Destructible, FileUploadService, InputModel, ProgressEvent, UploadedFile, FileUploadEvent } from "@anzar/core"
import { FileBackend } from "@backend/pyzar.api"
import { FsService } from "../fs.service"


@Injectable()
export class FileUploaderService extends Destructible {
    // private _inputModels: InputModel<File | UploadedFile>[] = []
    private _inputModels: Map<InputModel<File | UploadedFile>, any> = new Map()

    public constructor(
        @Inject(FileUploadService) private readonly uploadSvc: FileUploadService,
        @Inject(FsService) private readonly fsService: FsService,
        @Inject(FileBackend) private readonly fileBackend: FileBackend) {
        super()
    }

    public register(model: InputModel<File>, meta?: any) {
        this._inputModels.set(model, meta)
    }

    public unregister(model: InputModel<File>) {
        this._inputModels.delete(model)
    }

    public upload(directory: string): Observable<FileUploadEvent> {
        let needToUpload: Array<[InputModel<File>, any]> = []
        let updateMeta: Array<[InputModel<UploadedFile>, any]> = []

        this._inputModels.forEach((meta, model) => {
            if (model.value instanceof File) {
                needToUpload.push([model as any, meta])
            } else if (model.value instanceof UploadedFile) {
                updateMeta.push([model as any, meta])
            }
        })

        const updateMetaStream = updateMeta.map(value => {
            return this.fileBackend.save({ data: { id: value[0].value.id, meta: value[1] } })
        })

        let total = 0
        let done = 0

        const uploadStream = needToUpload.map(([model, meta]) => {
            total = model.value.size
            let postData = { meta: "" }
            if (meta) {
                postData.meta = JSON.stringify(meta)
            }
            return this.uploadSvc.upload(this.fsService.getUploadUrl(directory), "file", model.value, postData).pipe(
                tap(event => {
                    if (event.state === "done") {
                        done += event.total
                        const res = event.response
                        model.value = this.fsService.newUploadedFile(res as any) as any
                    }
                    model.emitProgress(event)
                })
            )
        })

        if (updateMetaStream.length === 0 && uploadStream.length === 0) {
            return of({ total: 1, current: 1, percent: 1, state: "done" })
        } else {
            let streams: Array<Observable<FileUploadEvent>> = []

            if (updateMetaStream.length) {
                total += 1
                streams.push(forkJoin(updateMetaStream).pipe(
                    map(v => {
                        done += 1
                        return { total: 1, current: 1, percent: 1, state: "done" } as FileUploadEvent
                    })
                ))
            }

            if (uploadStream.length) {
                streams = streams.concat(uploadStream)
            }

            return concat(...streams).pipe(
                map(event => {
                    const c = event.state === "done" ? done : done + event.current
                    return {
                        state: c >= total && event.state === "done" ? "done" : "progress",
                        total: total,
                        current: c,
                        percent: c / total
                    }
                })
            )
        }
    }
}
