import { Component, Inject, forwardRef, ChangeDetectorRef } from "@angular/core"
import { FormGroup, FormControl } from "@angular/forms"
import { tap } from "rxjs/operators"

import { MaintainerBackend, Maintainer } from "@backend/org.api"

import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { MaintainerEditorService } from "./maintainer-editor.service"


@Component({
    selector: ".rege-maintainer-basic-data",
    templateUrl: "./maintainer-basic-data.component.pug",
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => MaintainerBasicDataComponent) }
    ]
})
export class MaintainerBasicDataComponent extends AbstractCLEditorBlock<MaintainerEditorService> {
    public readonly maintainer: Maintainer

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: MaintainerEditorService,
        @Inject(MaintainerBackend) protected readonly maintainerBackend: MaintainerBackend,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef) {
        super(editorSvc, new FormGroup({
            title: new FormControl()
        }))
    }

    public ngOnInit() {
        super.ngOnInit()
        this.destruct.subscription(this.editorSvc.maintainer$).subscribe(maintainer => {
            (this as { maintainer: Maintainer }).maintainer = maintainer
            this.form.reset({
                title: maintainer ? maintainer.title : null
            })
            this.cdr.detectChanges()
        })
    }

    public save() {
        let data = this.form.value

        if (this.maintainer) {
            data.id = this.maintainer.id
        }

        return this.maintainerBackend.save({ data }).pipe(tap(level => {
            this.editorSvc.id = level.id
        }))
    }
}
