import { Inject, InjectionToken, Injectable } from "@angular/core"

import { UploadedFile } from "@anzar/core"
import { File } from "@backend/pyzar.api"


export const FILE_UPLOAD_ENDPOINT = new InjectionToken<string>("FILE_UPLOAD_ENDPOINT")
export const FILE_DOWNLOAD_ENDPOINT = new InjectionToken<string>("FILE_DOWNLOAD_ENDPOINT")
export const FILE_INLINE_ENDPOINT = new InjectionToken<string>("FILE_INLINE_ENDPOINT")
export const FILE_IMAGE_ENDPOINT = new InjectionToken<string>("FILE_IMAGE_ENDPOINT")


export type FileId = File | number


@Injectable({ providedIn: "root" })
export class FsService {
    public constructor(
        @Inject(FILE_UPLOAD_ENDPOINT) public readonly upEndpoint: string,
        @Inject(FILE_DOWNLOAD_ENDPOINT) public readonly downEndpoint: string,
        @Inject(FILE_INLINE_ENDPOINT) public readonly inlineEndpoint: string,
        @Inject(FILE_IMAGE_ENDPOINT) public readonly imageEndpoint: string) {
    }

    public newUploadedFile(file: File | UploadedFile): UploadedFile {
        if (file instanceof UploadedFile) {
            return file
        }

        let meta = file.meta
        if (typeof meta === "string") {
            meta = JSON.parse(meta)
        }

        return new UploadedFile({
            id: file.id,
            name: file.title,
            size: file.size,
            mime: file.mime_type,
            downloadUrl: this.getDownloadUrl(file),
            inlineUrl: this.getInlineUrl(file),
            meta: meta
        })
    }

    public getDownloadUrl(file: FileId): string {
        return `${this.downEndpoint}/${_fileId(file)}`
    }

    public getInlineUrl(file: FileId): string {
        return `${this.inlineEndpoint}/${_fileId(file)}`
    }

    public getImageUrl(file: FileId, width: number, height: number): string {
        return `${this.imageEndpoint}/${_fileId(file)}/${width}/${height}`
    }

    public getUploadUrl(path: string): string {
        return `${this.upEndpoint}${path}`
    }
}

function _fileId(id: FileId): number {
    if (typeof id === "number") {
        return id
    } else {
        return id.id
    }
}
