import { Component, Input, Inject, forwardRef } from "@angular/core"
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms"
import { Observable, of, forkJoin } from "rxjs"
import { take, tap, filter, switchMap, map } from "rxjs/operators"

import { LoadFields, FileDownloadService, ToastService } from "@anzar/core"
import { TemplateType } from "@backend/enums.api"
import { Template, TemplateBackend } from "@backend/documents.api"
import { File } from "@backend/pyzar.api"
import { LevelBackend, Level } from "@backend/org.api"
import { AbstractCLEditorBlock, AbstractCLEditorService } from "@pyzar/common.module"
import { FsService, FileUploaderService } from "@pyzar/fs.module"


const TEMPLATE_FIELDS: LoadFields<Template> = [
    "id", "level_id", "title", "type",
    { typev: ["label"] },
    { file: ["id", "mime_type", "path", "title", "size"] }
]


@Component({
    selector: ".rege-documents-block",
    templateUrl: "./documents-block.component.pug",
    host: {
        "[style.padding]": "'8px 0px'"
    },
    providers: [
        { provide: AbstractCLEditorBlock, useExisting: forwardRef(() => DocumentsBlockComponent) },
        FileUploaderService
    ]
})
export class DocumentsBlockComponent extends AbstractCLEditorBlock {
    @Input() public group: string
    @Input() public levelId: number

    public readonly typeSrc = TemplateType.DATA

    public get documentControls() { return (this.form.get("documents") as FormArray).controls }

    private _disbaleChange = false
    public _inherited: Array<[Template, Level]> = []

    public constructor(
        @Inject(AbstractCLEditorService) editorSvc: AbstractCLEditorService,
        @Inject(TemplateBackend) private readonly tplBackend: TemplateBackend,
        @Inject(FsService) private readonly fsService: FsService,
        @Inject(FileUploaderService) private readonly uploader: FileUploaderService,
        @Inject(FileDownloadService) private readonly fileDownload: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(LevelBackend) private readonly levelBackend: LevelBackend) {
        super(editorSvc, new FormGroup({
            documents: new FormArray([])
        }))

        this.documentControls.push(this._createRow())

        const ctrl = this.form.get("documents") as FormArray
        this.destruct.subscription(ctrl.valueChanges).subscribe(values => {
            if (this._disbaleChange) {
                return
            }
            this._disbaleChange = true

            let hasEmpty = false

            for (let i = 0, l = values.length; i < l; i++) {
                let rowCtrl = ctrl.at(i)
                let row = values[i]
                let c1 = rowCtrl.get("file")
                let c2 = this.group === "form" ? rowCtrl.get("title") : rowCtrl.get("type")

                if (row.file) {
                    c1.setValidators([Validators.required])
                    c2.setValidators([Validators.required])
                } else {
                    c1.clearValidators()
                    c2.clearValidators()
                    hasEmpty = true
                }
                c1.updateValueAndValidity()
                c2.updateValueAndValidity()
            }

            // const hasEmpty = values.filter((v: any) => !v.file).length > 0
            if (!hasEmpty) {
                ctrl.push(this._createRow())
            }

            ctrl.markAsTouched()
            ctrl.markAsPristine()
            ctrl.markAsDirty()
            ctrl.updateValueAndValidity()
            this._disbaleChange = false
        })

        // this.destruct.subscription(this.form.statusChanges).subscribe(status => {
        //     console.log("status", status, this.form.dirty, this.form.valid)
        // })
    }

    public ngOnInit() {
        super.ngOnInit()
        this._reload()
    }

    public save(): Observable<any> {
        return this.uploader.upload("/documents")
            .pipe(
                filter(e => e.state === "done"),
                switchMap(_ => {
                    let templates = (this.form.value.documents as any[])
                        .filter((v: any) => !!v.file)
                        .map((v: any) => {
                            if (this.group === "form") {
                                v.type = this.group
                            }
                            return v
                        })

                    return this.tplBackend
                        .update({ templates, group: this.group, level_id: this.levelId })
                        .pipe(tap(this._reload.bind(this)))
                })
            )
    }

    public deleteRow(index: number) {
        const ctrl = this.form.get("documents") as FormArray
        ctrl.removeAt(index)
        ctrl.markAsTouched()
        ctrl.markAsPristine()
        ctrl.markAsDirty()
        ctrl.updateValueAndValidity()
    }

    private _createRow(tpl?: Template) {
        return new FormGroup({
            id: new FormControl(tpl ? tpl.id : null),
            file: new FormControl(tpl ? this.fsService.newUploadedFile(tpl.file) : null),
            type: new FormControl(tpl ? tpl.type : null),
            title: new FormControl(tpl ? tpl.title : null),
        })
    }

    private _reload() {
        const parents = this.levelBackend.get_parents({ id: this.levelId })
        const docs = this.tplBackend.search({
            filter: { "typev.group": this.group, "level_id": this.levelId }
        }, { loadFields: TEMPLATE_FIELDS })


        forkJoin(docs, parents).subscribe(([tpls, parents]) => {
            this._disbaleChange = true

            const ctrl = this.form.get("documents") as FormArray
            ctrl.controls.length = 0
            this._inherited.length = 0

            for (const tpl of tpls) {
                if (tpl.level_id !== this.levelId) {
                    let parent = parents.find(parent => parent.id === tpl.level_id)
                    this._inherited.push([tpl, parent])
                } else {
                    ctrl.push(this._createRow(tpl))
                }
            }

            ctrl.push(this._createRow())

            this._disbaleChange = false
        })
    }

    public downloadFile(file: File) {
        this.fileDownload.download(this.fsService.getDownloadUrl(file))
            .pipe(this.toast.handleFileDownload({ align: "bottom center" }))
            .subscribe()
    }
}
